import { mutate } from 'swr'
import { FirstArg } from './types'
import type { Rename } from '@beatgig/helpers/types/rename'
import { AlgoliaService } from '@beatgig/api-services/algolia'

export class AlgoliaApi {
  static getSecuredKey = ({
    userId,
    ...props
  }: Rename<
    Required<FirstArg<typeof AlgoliaService.getSecuredApiKeyForIndex>>,
    'adminOnBehalfOf',
    'userId'
  >) => {
    return AlgoliaService.getSecuredApiKeyForIndex({
      ...props,
      adminOnBehalfOf: userId,
    })
  }
  static securedKeySWRKey = ({
    indexName,
    userId,
  }: FirstArg<typeof AlgoliaApi['getSecuredKey']>) => {
    type Key = [indexName: typeof indexName, userId: string, key: 'secured-key']
    const key: Key = [indexName, userId, 'secured-key']

    return key
  }
  static refetchSecuredKey = (
    keyProps: FirstArg<typeof AlgoliaApi['securedKeySWRKey']>
  ) => {
    return mutate(AlgoliaApi.securedKeySWRKey(keyProps))
  }
  static get apiKey() {
    if (process.env.NEXT_PUBLIC_BACKEND_ENV === 'sandbox') {
      return '704aca26e8ee4ce026fc44c0b51a2a60'
    }

    return 'de311af0a27b3a94dd13a4517abca243'
  }
  static get appId() {
    if (process.env.NEXT_PUBLIC_BACKEND_ENV === 'sandbox') {
      return '6QQEOL42WH'
    }

    return 'W9NFX9BU3T'
  }
}
