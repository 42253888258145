import { AlgoliaApi } from '@beatgig/api/algolia'
import algoliasearch, { AlgoliaSearchOptions } from 'algoliasearch/lite'

// const algoliaId = '3ZF42MPZTC'
// let algoliaSearchKey = 'f89474a2f945568485c37264a7aa8f1d'

const algoliaId = AlgoliaApi.appId
let algoliaSearchKey = AlgoliaApi.apiKey

let globalAlgoliaOptions: AlgoliaSearchOptions = {}

export const makeCustomAlgoliaClient = (
  apiKey: string,
  options?: AlgoliaSearchOptions
) => {
  return algoliasearch(algoliaId, apiKey, {
    ...globalAlgoliaOptions,
    ...options,
  })
}

export let algolia = algoliasearch(
  algoliaId, // app id
  algoliaSearchKey, // search only key
  globalAlgoliaOptions
)

/**
 * For admin search, authenticated key
 */
export const updateAlgoliaSearchKey = (key: string) => {
  algoliaSearchKey = key
  algolia = algoliasearch(algoliaId, key, globalAlgoliaOptions)
}

export const updateAlgoliaUserTokenOnAuthStateChanged = (
  uid: string | null
) => {
  if (!uid) {
    globalAlgoliaOptions = {}
    algolia = algoliasearch(algoliaId, algoliaSearchKey, globalAlgoliaOptions)
    return
  }

  globalAlgoliaOptions = {
    ...globalAlgoliaOptions,
    headers: {
      ...globalAlgoliaOptions.headers,
      'X-Algolia-UserToken': uid,
    },
  }

  algolia = algoliasearch(
    algoliaId, // app id
    algoliaSearchKey, // search only key,
    globalAlgoliaOptions
  )
}

// export const AlgoliaIndices = {
//   // talentSearch: 'full-artists-objects',
//   // talentSearch: 'confirmed-artists',
//   /**
//    * For admin-facing screen
//    */
//   adminSearch: 'sept-30-testing',
//   /**
//    * For public-facing screen
//    */
//   talentSearch: 'full-artists-published-10_5',
//   talentSearchSortedByPriceAsc: 'asc-full-artists-published',
//   talentSearchSortedByPriceDesc: 'desc-full-artists-published',
//   talentSearchSortedByRatingDesc: 'rating-full-artists-published',
//   adminAllUsersIndex: 'users',
//   adminAllVenues: 'venues',
//   adminAllRecommendedBookings: 'recommended_bookings',
//   talentSearchSortedByPopularity: 'popularity-full-artists-published',
//   eventDiscovery: 'published_bookings',
//   adminBookings: 'display_bookings',
//   adminArtistsLastUpdatedDesc: 'admin-artists-last-updated-desc',
// } as const
